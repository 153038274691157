<template>
    <div id="content" class="user-management">
        <div class="bar-actions">
            <Button bType="cancel_changes" :bCallback="goBack" customClass="btn-spaced"></Button>
            <Button bType="save" :bCallback="saveUser" customClass="btn-action" :class="{ disabled: !id_new || !email_new || validEmail || emptyAnswer.email }" :bLabel="$route.params.id ? 'menu_aux.action.save' : 'menu_aux.action.save'"></Button>
        </div>

        <div class="form-container">
            <div class="form">
                <div class="form-title">
                    <h1 class="form-title">{{ $t('user.form.basic_info') }}</h1>
                </div>
                <div class="label-container" :class="{ disabled: !id_new || !email_new }">
                    <div class="left-container">
                        <h2 class="label-form">{{ $t('user.form.avatar') }}</h2>
                        <p class="description">{{ $t('user.form.avatar_desc') }}</p>
                        <Avatar :avatarSelected="user.avatar" @setImage="setImage"></Avatar>
                    </div>
                    <div class="right-container" :class="{ disabled: !id_new || !email_new }">
                        <h2 class="label-form">{{ $t('user.form.user_rol') }}</h2>
                        <p v-if="emptyAnswer.rol" class="error-form" v-html="$t('user.form.error.rol')"></p>
                        <p v-else class="description">
                            {{ $t('profile.role_desc') }}
                        </p>
                        <div class="user-answer">
                            <input v-if="role" class="input read-only w100" readonly :value="role.name + ' (' + $t(role.default_name) + ' ' + $t('user.form.level') + ' ' + role.level + ')'" />
                        </div>
                    </div>
                </div>
                <!-- ROL DE USUARIO ANTIGUO -->
                <!-- <h2 class="label-form">{{$t('user.form.user_rol')}}</h2>
          <p v-if="emptyAnswer.rol" class="error-form" v-html="$t('user.form.error.rol')"></p>
          <p v-else class="description">{{$t('user.form.user_rol_descrip')}}</p>
          <div class="user-answer">
            <v-select
              hide-details
              class="select"
              v-model="user.rol"
              :items="Object.values(optionsRoles)"
              :disabled="!id_new || !email_new"
              @change="role()"
              item-text="name"
              item-value="id"
              dense
              :no-data-text="$t('supervise.filter.no_results')"
            ></v-select>
          </div> -->
                <div class="label-container">
                    <div class="left-container" :class="{ disabled: !id_new || !email_new }">
                        <!-- Name -->
                        <Input v-model="user.name" :gOptions="{ mandatory: true }" :gPlaceholder="$t('user.form.name_placeholder')" :gLabel="$t('user.user_name')" :gMessage="{ error: $t('user.form.error.name') }" :gState="emptyAnswer.name ? 'error' : ''" gSize="m"></Input>
                    </div>
                    <div class="right-container" :class="{ disabled: !id_new || !email_new }">
                        <!-- Surname -->
                        <Input v-model="user.surname" :class="{ disabled: !id_new }" :placeholder="$t('user.form.surname_placeholder')" :gLabel="$t('user.form.surname')" :gMessage="{ error: $t('user.form.error.surname') }" :gState="emptyAnswer.surname ? 'error' : ''" gSize="m"></Input>
                    </div>
                </div>
                <div class="label-container">
                    <div class="left-container" :class="{ disabled: !email_new }">
                        <p v-if="emptyAnswer.locations" class="error-form" v-html="$t('user.form.error.locations')"></p>
                        <div class="sites" v-if="accessLocations()">
                            <h2 class="label-form">{{ $t('profile.access_locations') }}</h2>
                            <p class="description" v-html="$t('profile.location_desc')"></p>
                            <Tags :tags="Object.values(user.locations)" :backImage="'location_inactive_s30.svg'" :limit="false" :nameTag="'name'" value="id"></Tags>
                        </div>
                        <div class="custom" v-else-if="accessGroups()">
                            <h2 class="label-form">{{ $t('profile.access_groups') }}</h2>
                            <p class="description" v-html="$t('profile.group_desc')"></p>
                            <Tags :tags="Object.values(user.groups)" :backImage="'menu_inactive_s30.svg'" :limit="false" :nameTag="'name'" value="id"></Tags>
                        </div>
                        <div class="els" v-else-if="user && user.rol && role.level < 6">
                            <h2 class="label-form">{{ $t('profile.access_locations') }}</h2>
                            <div class="info-box access">{{ $t('profile.no_access') }}</div>
                        </div>
                        <div class="els" v-else>
                            <h2 class="label-form">{{ $t('profile.access_locations') }}</h2>
                            <div class="info-box access">
                                {{ $t('profile.global_access') }}
                            </div>
                        </div>
                    </div>

                    <div class="right-container" :class="{ disabled: !email_new }">
                        <h2 class="label-form">{{ $t('user.id') }}</h2>
                        <p class="description" v-html="$t('profile.id_desc')"></p>
                        <p v-if="emptyAnswer.id" class="error-form" v-html="$t('user.form.error.id')"></p>
                        <div class="user-answer">
                            <input type="text" readonly :value="user.identification" class="input w100" />
                        </div>
                    </div>
                </div>
                <div class="label-container">
                    <div class="left-container">
                        <Input @blur="emailCheck()" :gOptions="{ mandatory: true }" :gPlaceholder="$t('user.form.email_placeholder')" v-model="newEmail" :gLabel="$t('user.form.email')" :gMessage="{ default: $t('user.form.email_description'), error: $t('user.form.error.email_used'), error2: $t('user.form.email_description'), error3: $t('user.form.error.not_email') }" :gState="!email_new ? 'error' : emptyAnswer.email ? 'error2' : validEmail ? 'error3' : ''" gSize="m"></Input>
                    </div>
                    <div class="right-container">
                        <!-- New development -->
                        <Input v-if="optionsLanguages" :gOptions="{ returnObject: false, mandatory: true, itemValue: 'code' }" :gLabel="$t('settings.language')" :gState="emptyAnswer.language ? 'error' : ''" :gMessage="{ error: $t('settings.language_empty') }" gType="select" v-model="user.language" :gItems="Object.values(optionsLanguages)" gSize="m"></Input>
                    </div>
                </div>
                <div class="label-container spaced" :class="{ disabled: !id_new }">
                    <div class="left-container" :class="{ disabled: !email_new }" v-if="canAccessApp()">
                        <div class="up">
                            <h1 class="form-title">{{ $t('user.form.alex_app_access') }}</h1>
                            <!-- SWITCH HERE PLS -->
                            <div class="switch access">
                                <div class="switch-click" @click="popupAlex()">
                                    <input class="switch-input-form access" type="checkbox" v-model="hasPin" disabled="true" />
                                </div>
                            </div>
                        </div>
                        <div class="access-container" v-if="hasPin">
                            <h2 class="label-form">{{ $t('user.form.pin_key') }}</h2>
                            <p class="description" v-html="$t('profile.pin_desc')"></p>
                            <p v-if="emptyAnswer.pin" class="error-form" v-html="$t('user.form.error.self_pin')"></p>
                            <div class="temporal_pin" v-if="newPin !== false">
                                <input type="text" autocomplete="off" class="input white" maxlength="4" inputmode="numeric" name="pin" v-model="newPin" :class="{ disabled: !id_new }" @input="onlyNumbers" @change="onlyNumbers" id="pin" :placeholder="$t('user.form.pin_key_placeholder')" />
                                <Button bType="refresh" v-if="user.pin.length < 5" :bCallback="popupSetPinRandom" bLabel="user.form.reset_pin" customClass="btn-secondary"></Button>
                            </div>
                            <div class="user-answer" v-if="user.pin.length > 4">
                                <Button :bCallback="popupSetPin" bType="edit" bLabel="user.form.modify_pin" customClass="btn-secondary no-margin-left"></Button>
                            </div>
                        </div>
                        <div class="access-container dashboard" v-else>
                            {{ $t('profile.no_access_app') }}
                        </div>
                    </div>
                    <div class="right-container" v-if="canAccessDashboard()">
                        <h1 class="form-title-dashboard">{{ $t('user.form.dashboard_access') }}</h1>
                        <transition name="fade">
                            <div class="access-container dashboard" v-if="changeToNewPass">
                                <div class="user-answer user-password">
                                    <Input class="password-input" gType="password" :gMessage="{ error: $t('sites.form.error.pass') }" :gOptions="{ mandatory: true }" v-model="newPassword" :gLabel="$t('user.form.new_password')" :gState="emptyAnswer.passMin ? 'error' : ''" gSize="m"></Input>
                                    <Button :bCallback="cancelPassword" bType="cancel" customClass="btn-secondary"></Button>
                                </div>
                                <Button :bCallback="generatePass" bType="refresh" bLabel="user.form.generate_pass" customClass="btn-secondary no-margin-left margin-top"></Button>
                            </div>
                            <!-- TODO ESTÁ BIEN SOLO MOSTRAMOS BOTÓN CAMBIAR CONTRASEÑA -->
                            <div v-else-if="user.password || user.password == ''">
                                <Button v-if="!changePassword" bType="edit" :bCallback="resetPass" bLabel="profile.change_pass" :customClass="['btn-secondary btn-clear', { disabled: !id_new }]"></Button>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from '@/i18n'
import Tags from '@/components/ui/Tags'
import Avatar from '@/components/domain/user/avatarSelector'

export default {
    name: 'UserProfile',
    components: { Tags, Avatar },
    data() {
        return {
            newEmail: '',
            oldEmail: false,
            oldPassword: false,
            validEmail: false,
            emailReg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            newPassword: false,
            userWithEmail: false,
            changePassword: false,
            roleName: '',
            changeToNewPass: false,
            emailValidation: '',
            user: {},
            sendForm: true,
            id_new: true,
            email_new: true,
            sites: 'locations',
            emptyAnswer: {
                name: false,
                surname: false,
                id: false,
                rol: false,
                email: false,
                pin: false,
                notSameEmail: false,
                locations: false,
                notSamePass: false,
                passMin: false
            },
            hasPin: false,
            newPin: false,
            pinChanged: false
        }
    },
    computed: {
        role() {
            var role = this.$store.getters['employee/getRoles'](this.user.rol)
            if (role) {
                this.roleName = role.name
                return role
            }
        },
        allRoles: function () {
            return _.orderBy(this.optionsRoles, 'level')
        },
        optionsLocations() {
            return [
                { value: 'locations', name: i18n.t('user.sites') },
                { value: 'groups', name: i18n.t('user.filters.territories') }
            ]
        },
        optionsSites() {
            if (Object.values(this.$store.getters['employee/getFilterSites']()).length == 0) {
                this.$store.dispatch('employee/loadFilters', {}).then(function () {})
            }
            return Object.values(this.$store.getters['employee/getFilterSites']())
        },
        optionsRoles() {
            return this.$store.getters['employee/getFilterRoles']()
        },
        optionsGroups() {
            return Object.values(this.$store.getters['employee/getFilterGroups']())
        },
        optionsLanguages() {
            return this.$store.getters['getAvailableLanguages']
        }
    },
    methods: {
        generatePass() {
            var length = 8,
                charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
                retVal = ''
            for (var i = 0, n = charset.length; i < length; ++i) {
                retVal += charset.charAt(Math.floor(Math.random() * n))
            }
            this.newPassword = retVal
        },
        cancelPassword() {
            this.user.password = this.oldPassword
            this.changePassword = false
            this.changeToNewPass = false
            this.newPassword = ''
        },
        popupStatus() {
            var selfThis = this
            if (selfThis.statusUser == '1' || selfThis.statusUser == true || selfThis.statusUser == '2') {
                selfThis.$popup.confirm({
                    message: selfThis.$t('user.form.info.inactive_switch'),
                    textSave: selfThis.$t('user.form.info.inactive_confirm'),
                    callSave: function () {
                        selfThis.statusUser = false
                    },
                    callCancel: function () {
                        selfThis.statusUser = true
                    }
                })
            } else {
                selfThis.statusUser = true
            }
        },
        accessLocations() {
            if (this.role) {
                if (this.role.level > 5) {
                    return false
                } else {
                    if (Object.values(this.user.locations).length != 0) {
                        return true
                    } else {
                        return false
                    }
                }
            }
        },
        setImage(img) {
            // console.log(img)
            this.user.avatar = img
        },
        accessGroups() {
            if (this.role) {
                if (this.role.level > 5) {
                    return false
                } else {
                    if (Object.values(this.user.groups).length > 0) {
                        return true
                    } else {
                        return false
                    }
                }
            }
        },
        popupAlex() {
            var selfThis = this
            if (selfThis.user.pin != '' && selfThis.user.pin != null) {
                selfThis.$popup.confirm({
                    message: selfThis.$t('user.form.info.alex_inactive'),
                    textSave: selfThis.$t('user.form.info.alex_confirm'),
                    callSave: function () {
                        selfThis.user.pin = false
                        selfThis.hasPin = false
                        selfThis.pinChanged = false
                    },
                    callCancel: function () {}
                })
            } else {
                selfThis.newPin = true
                selfThis.pinChanged = true
                selfThis.hasPin = true
                this.user.pin = '1111'
                this.newPin = ''
            }
        },
        resetPass() {
            this.oldPassword = this.user.password
            this.user.password = ''
            this.newPassword = ''
            this.changePassword = true
            this.changeToNewPass = true
        },
        emailCheckValidation() {
            if (this.newEmail != this.emailValidation) {
                this.emptyAnswer.notSameEmail = true
            } else {
                this.emptyAnswer.notSameEmail = false
            }
        },

        selectNewRole(newRole) {
            this.user.rol = newRole.id
            this.allRolesList = false
        },
        canAccessApp() {
            if (this.role) {
                var levelCurrentUser = this.role.level
                if (levelCurrentUser >= 1 || levelCurrentUser == 8) {
                    return true
                }
            }
        },
        canAccessDashboard() {
            if (this.role) {
                var levelCurrentUser = this.role.level
                if (levelCurrentUser >= 3 && levelCurrentUser <= 8) {
                    return true
                } else {
                }
            }
        },
        createUserLowerLevel(level) {
            var role = this.$store.getters['employee/getRoles'](this.roleLoggedUser.rol)
            if (role) {
                if (role.level > level) {
                    return true
                }
                return false
            }
        },
        changeRole() {
            this.allRolesList = true
        },
        popupSetPinRandom() {
            var val = Math.floor(1000 + Math.random() * 9000)
            this.newPin = val
        },
        onlyNumbers(event) {
            let val = event.target.value.trim()
            // It can only be positive integer or empty, and the rule can be modified according to the requirement.
            if (/^[0-9]\d*$|^$/.test(val)) {
                this.oldNum = val
            } else {
                event.target.value = ''
            }
        },

        setPin() {
            this.user.pin = ''
            this.newPin = ''
            this.pinChanged = true
        },
        popupSetPin() {
            var self = this

            self.$popup.confirm({
                message: self.$t('user.form.info.reset_self_pin'),
                textSave: self.$t('user.form.info.reset_pin_save'),
                callSave: function () {
                    self.setPin()
                }
            })
        },

        load() {
            this.$store.dispatch('languages')
            var userLoggedId = this.$store.getters['login/getLocalEmployee'].employee_id
            var loggedUser = this.$store.getters['employee/getAccountEmployee'](userLoggedId)
            // console.log(loggedUser)
            if (loggedUser) {
                this.user = { ...loggedUser }
                if (this.user.pin != null && this.user.pin != '' && this.user.pin) {
                    this.hasPin = true
                }

                this.newEmail = this.user.email
                this.oldEmail = this.user.email
            }
        },
        isEmailValid() {
            this.newEmail == '' || this.newEmail == null ? (this.validEmail = false) : this.emailReg.test(this.newEmail) ? (this.validEmail = false) : (this.validEmail = true)
        },
        refreshSearch() {
            this.user.locations = []
            this.user.groups = []
        },
        emailCheck() {
            this.isEmailValid()
            var self = this
            var params = { email: this.newEmail, id: this.user.id }
            if (!this.newEmail == '') {
                this.$store.dispatch('employee/emailCheck', params).then(function (response) {
                    self.email_new = response
                    return response
                })
            }
            this.emptyAnswer.email = false
            return true
        },
        // La validación está hecha ahora enviaremos el formulario a la api
        sendFormUser() {
            // console.log(this.user)
            var self = this
            self.$overlay.loading()

            if (this.user.avatar.includes('@') || this.user.avatar.includes('http')) {
                this.user.avatar = this.user.avatar.split('/')[this.user.avatar.split('/').length - 1]
            }
            if (this.pinChanged) {
                this.user.pin = sha256(this.newPin)
            }
            if (this.newPassword !== '' && this.changeToNewPass) {
                this.user.password = sha256(this.newPassword)
            }

            if (this.user.groups) {
                this.user.groups
                var group = []
                for (var i in this.user.groups) {
                    var idx = this.user.groups[i]
                    if (idx) {
                        group.push(idx.id)
                    }
                }
                this.user.groups = group.join()
            }

            if (this.user.locations) {
                var location = []
                for (var i in this.user.locations) {
                    var idx = this.user.locations[i]
                    if (idx) {
                        location.push(idx.id)
                    }
                }
                this.user.locations = location.join()
            }
            if (!this.hasPin) {
                this.user.pin = null
            }

            this.$store.dispatch('employee/editUserLogged', this.user).then(function (response) {
                self.$overlay.hide()
                self.$snackbar.open({
                    message: self.$t('settings.edit_success'),
                    customClass: 'success',
                    duration: 5000
                })
                self.user = { ...response }
                if (self.changeToNewPass) {
                    self.changeToNewPass = false
                }
                self.changePassword = false
            })
        },
        validationPopup() {
            var self = this
            self.$overlay.loading()
            self.sendForm = true
            self.user.email = self.newEmail
            var pass = self.emptyAnswer.notSamePass
            // set all values of errors to false
            self.emptyAnswer = _.mapValues(self.emptyAnswer, () => false)
            if (self.user.name === '') {
                self.sendForm = false
                self.emptyAnswer.name = true
            }

            if (self.user.email === '') {
                self.sendForm = false
                self.emptyAnswer.email = true
            }
            if (pass && self.changeToNewPass) {
                self.sendForm = false
                self.emptyAnswer.notSamePass = true
            }
            if (self.changeToNewPass && self.newPassword.length < 8) {
                self.sendForm = false
                self.emptyAnswer.passMin = true
            }

            if (self.hasPin) {
                if (self.pinChanged) {
                    if (self.newPin == '') {
                        self.sendForm = false
                        self.emptyAnswer.pin = true
                    }
                }
            }
            if (self.newPin != '') {
                self.user.pin = self.newPin
                self.pinChanged = true
            }
            if (self.hasPin == true && self.pinChanged == true && self.newPin == '') {
                self.sendForm = false
                self.emptyAnswer.pin = true
            }

            if (self.emptyAnswer.id) {
                self.sendForm = false
            }
            if (self.sendForm) {
                if (self.user.identification) {
                    var self = self
                    self.$store
                        .dispatch('employee/identificationUsed', {
                            identification: self.user.identification,
                            employee_id: self.user.id
                        })
                        .then(function (response) {
                            if (response) {
                                self.sendFormUser()
                            } else {
                                self.id_new = response
                                self.$overlay.hide()
                            }
                        })
                } else {
                    self.sendFormUser()
                }
            } else {
                self.$overlay.hide()
            }
        },

        // Validaciones antes de enviar el formulario
        saveUser() {
            var self = this

            if (this.newEmail != this.oldEmail) {
                self.$popup.confirm({
                    message: self.$t('user.form.change_email', { email: self.newEmail }),
                    textSave: self.$t('user.form.confirm_changes'),
                    callSave: function () {
                        self.$popup.close()
                        self.validationPopup()
                    },
                    callCancel: function () {
                        self.$popup.close()
                    }
                })
            } else {
                self.$overlay.loading()
                this.sendForm = true
                self.user.email = self.newEmail
                var pass = this.emptyAnswer.notSamePass
                // set all values of errors to false
                this.emptyAnswer = _.mapValues(this.emptyAnswer, () => false)
                if (this.user.name === '') {
                    this.sendForm = false
                    this.emptyAnswer.name = true
                }

                if (this.user.email === '') {
                    this.sendForm = false
                    this.emptyAnswer.email = true
                }
                if (pass && this.changeToNewPass) {
                    this.sendForm = false
                    this.emptyAnswer.notSamePass = true
                }
                if (this.changeToNewPass && this.newPassword.length < 8) {
                    this.sendForm = false
                    this.emptyAnswer.passMin = true
                }

                if (this.hasPin) {
                    if (this.pinChanged) {
                        if (this.newPin == '') {
                            this.sendForm = false
                            this.emptyAnswer.pin = true
                        }
                    }
                }
                if (this.newPin != '') {
                    this.user.pin = this.newPin
                    this.pinChanged = true
                }
                if (this.hasPin == true && this.pinChanged == true && this.newPin == '') {
                    this.sendForm = false
                    this.emptyAnswer.pin = true
                }

                if (this.emptyAnswer.id) {
                    this.sendForm = false
                }
                if (this.sendForm) {
                    if (this.user.identification) {
                        var self = this
                        this.$store
                            .dispatch('employee/identificationUsed', {
                                identification: this.user.identification,
                                employee_id: this.user.id
                            })
                            .then(function (response) {
                                if (response) {
                                    self.sendFormUser()
                                } else {
                                    self.id_new = response
                                    self.$overlay.hide()
                                }
                            })
                    } else {
                        this.sendFormUser()
                    }
                } else {
                    self.$overlay.hide()
                }
            }
        },
        goBack() {
            location.reload()
        }
    },
    created() {
        this.load()
    },
    mounted() {},
    watch: {
        $route: 'load'
    }
}
</script>

<style lang="scss" scoped>
#content.user-management {
    .bar-actions {
        .btn-cancel_changes {
            margin-right: 0;
        }
    }

    .disabled {
        opacity: 0.55;
        pointer-events: none;
    }

    .inline-delete {
        margin: 60px auto 30px auto;
        width: 100%;
        text-align: center;

        .text {
            @include background($size: 16px, $image: img('delete_error.svg'), $position: left center);
            @include font-size(ml);
            color: $color-error-500;
            text-align: center;
            display: inline-block;
            padding-left: 18px;
            cursor: pointer;
        }
    }

    h1.form-title {
        width: 100%;
        &.noMarginBottom {
            margin-bottom: 8px;
        }
    }
    .description {
        &.w100 {
            width: 100%;
        }
    }
    .has-role {
        @include display-flex();
    }
    .btn-edit,
    .btn-cancel {
        align-self: center;
        width: auto;
        min-width: fit-content;
    }
    .no-margin-left {
        margin-left: 0px;
        margin-top: 9px;
    }
    .box-container {
        @include background($color: $color-neutral-200);
        @include display-flex();
        @include flex-wrap(wrap);
        width: 100%;
        height: auto;
        padding: 10px;
        border-radius: 4px;

        &.hasRole {
            width: unset !important;
        }

        .input-role {
            width: 18px;
        }
        .input-role:checked {
            background-color: red;
        }
        .box-name {
            @include font-size(sm);
            font-family: $text-bold;
            color: $color-black;
            padding-right: 4px;
        }
        .default-name {
            @include font-size(sm);
            font-family: $text-bold;
            color: $color-black;
        }
        .have-description {
            @include display-flex();
            @include flex-direction(column);
            width: 100%;
        }
        .no-role {
            @include display-flex();
            @include flex-direction(column);
            width: 50%;
            height: auto;
            padding: 10px;
            cursor: pointer;
        }
        .box-info {
            @include display-flex();

            .select-role {
                cursor: pointer;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 2px solid $color-neutral-600;
                margin-right: 6px;
                &.selected {
                    @include background($image: img('checked_main.svg'));
                    border: none;
                }
            }
        }
        .box-description {
            @include font-size(xs);
            font-family: $text-medium;
            color: $color-neutral-600;
            padding-left: 24px;
        }
    }
    .column-description {
        @include display-flex();
        @include flex-direction(column);
        cursor: pointer;
        width: 100%;
    }
    .up {
        @include display-flex();

        .access {
            margin-right: 5px;
            margin-top: 2px;
        }
    }
    .access-container {
        @include background($color: $color-neutral-50);
        @include font-size(xs);
        color: $color-neutral-600;
        margin-top: -16px;

        &.dashboard {
            margin-top: 14px;
        }
        .input-container.password-input.input-default.input-m {
            margin: 0px;
        }
        .input-container.password-input.input-error.input-m {
            margin: 0px;
        }
    }
    .form-container .input.read-only {
        padding-left: 5px;
    }
    .info-box {
        @include font-size(xs);
        color: $color-primary-500;
        background-color: $color-info-100;
        border-radius: 3px;
        padding: 6px;
        margin-bottom: 9px;

        strong {
            @include font-size(xs);
            color: $color-primary-500;
        }
        &.warning {
            background-color: $color-warning-100;
            color: $color-warning-800;
        }
        &.access {
            @include font-size(md);
            color: $color-primary-500;
            background-color: $color-info-100;
            width: fit-content;
        }
        &.same-user {
            @include font-size(sm);
            @include background($image: img('info_main.svg'), $position: 8px 8px, $size: 15px);
            padding-left: 28px;
            padding-top: 8px;
            color: $color-primary-500;
            align-self: flex-start;
            margin-top: 8px;
        }
    }
}
</style>
